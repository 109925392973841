import React from "react";
import moment from "moment";
import { Badge } from 'reactstrap';
import SessionNotes from "./SessionNotes";
const _ = require("lodash");

function CPTCodeList(props) {
    const { CPTCodeDetails, rpmMode, totalSessionRPM } = props;
    // code model generater check
    const codeModel = CPTCodeDetails && CPTCodeDetails.codeModel;
    // cpt code 99453 tables rows value
    const code99453 = CPTCodeDetails.verbalConsentAndWelcomeCompleteNote && CPTCodeDetails.verbalConsentAndWelcomeCompleteNote.map((note) => {
    return (
        <tr>
          <td>{note.subject  ? note.subject : '-'}</td>
          <td>{note.body  ? note.body : '-'}</td>
          <td>{note.createdBy ? note.createdBy.lname + " " + note.createdBy.fname : "-"}</td>
          <td>{moment(note.createdAt).format("MM/DD/YYYY")}</td>
        </tr>
      );
    })
    //  cpt code 99454 tables rows value
    const code99454 =  (
        <tr>
          <td>{CPTCodeDetails.nonAdhenrenceDay}</td>
          <td>{CPTCodeDetails.isEligible99454 ? <Badge color="success" pill outline> MET </Badge> : <Badge color="danger" pill outline> NOT MET </Badge>}</td>
        </tr>
      );
      //  cpt code 99458 tables rows value
      const code99458 =  (
        <tr>
          <td>{codeModel.cpt99458 && codeModel.cpt99458.length > 0 ? _.map(codeModel.cpt99458, (value, index) => ( <Badge color="warning" pill outline key={index} className="mr-2"> 99458 </Badge> ))  : '-'}</td>
          <td>{totalSessionRPM}</td>
          <td>{codeModel && _.size(codeModel.cpt99458) > 0 ? <Badge color="success" pill outline> MET </Badge> : <Badge color="danger" pill outline> NOT MET </Badge>}</td>
        </tr>
      );
    return (
      <div>
      <hr style={{ borderTop: "3px solid #5c5959" }} />
      <table class="table table-bordered page-break-ka-after mb-4">
      <thead>
        <tr>
          <th colSpan="4" className="text-center text-dark">
            <h3 class="font-weight-bold">
            Billing Requirements for CPT Code 99453
            </h3>
          </th>
        </tr>
        {code99453 && (
          <tr>
            <th className="text-center">Subject</th>
            <th className="text-center">Note</th>
            <th className="text-center">Created By</th>
            <th className="text-center">Date</th>
          </tr>
        )}
      </thead>
      <tbody className="text-center">
        {code99453 && code99453.length > 0 ? (
          code99453
        ) : (
          <tr>
            <th
              colSpan="4"
              className="text-black text-danger text-center mx-auto"
            >
              No Record Found
            </th>
          </tr>
        )}
        <tr>
        <th colSpan="4" className="text-center text-dark">
        <h6 class="font-weight-bold text-primary">
        First Month date that we billed for 99454 : {CPTCodeDetails.firstEligible99453 ? moment(CPTCodeDetails.firstEligible99453).format("MM/DD/YYYY") : '-'}
        </h6>
        </th>
        </tr>
      </tbody>
     </table>
     <hr style={{ borderTop: "3px solid #5c5959" }} />
     <table class="table table-bordered page-break-ka-after mb-4">
      <thead>
        <tr>
          <th colSpan="2" className="text-center text-dark">
            <h3 class="font-weight-bold">
            Billing Requirements for CPT Code 99454
            </h3>
          </th>
        </tr>
        {code99454 && (
          <tr>
            <th className="text-center">Non Adherence Days</th>
            <th className="text-center">Status</th>
          </tr>
        )}
      </thead>
      <tbody className="text-center">
        {code99454 ? (
          code99454
        ) : (
          <tr>
            <th
              colSpan="2"
              className="text-black text-danger text-center mx-auto"
            >
              No Record Found
            </th>
          </tr>
        )}
      </tbody>
    </table>
    <hr style={{ borderTop: "3px solid #5c5959" }} />
    <table class="table table-bordered page-break-ka-after mb-4">
    <thead>
      <tr>
        <th colSpan="2" className="text-center text-dark">
          <h3 class="font-weight-bold">
          Billing Requirements for CPT Code 99457 
          </h3>
        </th>
      </tr>
    </thead>
    <tbody>
    <tr>
    <SessionNotes 
    notes={rpmMode}
    pmMode={"RPM MODE"}
    pmModeTotal={totalSessionRPM}
    />
    <td className="text-center">{codeModel && _.size(codeModel.cpt99457) > 0 ? <Badge color="success" pill outline> MET </Badge> : <Badge color="danger" pill outline> NOT MET </Badge>}</td>
    </tr>
    </tbody>
    </table>
    <hr style={{ borderTop: "3px solid #5c5959" }} />
    <table class="table table-bordered page-break-ka-after mb-4">
    <thead>
      <tr>
        <th colSpan="3" className="text-center text-dark">
          <h3 class="font-weight-bold">
          Billing Requirements for CPT Code 99458
          </h3>
        </th>
      </tr>
      <tr>
      <th className="text-center">Billing Requirements for CPT Code 99458</th>
      <th className="text-center">Total RPM Time</th>
      <th className="text-center">Status</th>
    </tr>
    </thead>
    <tbody className="text-center">
        {code99458 ? (
          code99458
        ) : (
          <tr>
            <th
              colSpan="3"
              className="text-black text-danger text-center mx-auto"
            >
              No Record Found
            </th>
          </tr>
        )}
    </tbody>
    </table>
      </div>
    );    
  }
  
export default CPTCodeList;