import moment from "moment";
import React from "react";
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Col,
  Row} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import callingIcon from "../../files/called.png";
import UserName from "../Reusable/UserName";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import lottieCompleted from '../../files/animation/ic_fax_send_completed.json';
import lottiePending from '../../files/animation/ic_pending.json';
import ic_warning from '../../files/animation/ic_warning.json';
const _ = require("lodash");
function fullname(cell, row) {
  return <UserName user={row} />;
}

function billable(row) {
  let cptcode99457;
  let cptcode99458;
  let cptCode99490;
  let cptCode99439;
  let cptCode99453;
  let cptCode99454;
  const codeModel = row && row.codeModel ? row.codeModel : undefined;

  //*******************************************
  // RPM: CPT CODE - 99457 Information.
  //*******************************************
  //* 99457 - This RPM CPT code is populated every month, 
  //* IF the patient has had at least 20 minutes of review, a phone consultation with the patient AND at least 2 vital's reading needed
  //---------------------------------- START CPT CODE 99457 ----------------------------------//
  if (codeModel && _.size(codeModel.cpt99457) > 0) {
    cptcode99457 = [];
    _.map(codeModel.cpt99457, (value, index) => {
      cptcode99457.push(
        <div key={`key99457${row._id}`}>
          <button className="btn btn-sm btn-info session-code-button-radial" id={`popover99457${row._id}-${index}`}> 99457 </button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99457${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody> There has been an attestation of a phone consultation and at least 20 minutes of Remote Patient Monitoring performed on this patient this month. </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99457 ----------------------------------//

  //*******************************************
  // RPM: CPT CODE - 99458 Information.
  //*******************************************
  //* 99458 - This RPM CPT code is populated IF the patient qualified for 99457 AND had an additional 20 minutes of review, 
  //* in excess of the required 20 minutes for CPT 99457. For example if the patient’s total review time was more than 40 minutes, 
  //* we would populate one 99458 for the additional 20 minutes over the required 20 minutes. 
  //* If the total review time was over 60 minutes we would populate (2) 99458 CPT Codes because there were two separate additional 
  //* 20 minutes reviewed in excess to the initial 20 minutes required for CPT 99454. This CPT code can only be populated 3 times each month, 
  //* so if there is more than 80 minutes of review we only populate (3) 99458, no matter how much time. They limit billing for 99458 to only (3) per month.
  //---------------------------------- START CPT CODE 99458 ----------------------------------//  
  if (codeModel && _.size(codeModel.cpt99458) > 0) {
    cptcode99458 = [];
    _.map(codeModel.cpt99458, (value, index) => {
      cptcode99458.push(
        <div key={`key99458${row._id}`}>
          <button className="btn btn-sm btn-warning session-code-button-radial" id={`popover99458${row._id}-${index}`}>99458</button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99458${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              The patient has met the condition of a minimum requirement of 40
              grand total minutes of RPM a month.
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99458 ----------------------------------//
  //---------------------------------- END RPM LOGIC ---------------------------------------//

  //---------------------------------- START CCM LOGIC -------------------------------------//
  // Calculate the CCM TIME 
  //-----------------------------------------------------------------------------------------//  
  //*******************************************
  // CCM: CPT CODE - 99490 Information
  //*******************************************
  //* 99490 - This CCM CPT code is populated every month,
  //* IF the patient has had at least 20 minutes of review AND a phone consultation with the patient.
  //---------------------------------- START CPT CODE 99490 ----------------------------------//

  if (codeModel && _.size(codeModel.cpt99490) > 0) {
    cptCode99490 = [];
    _.map(codeModel.cpt99490, (value, index) => {
      cptCode99490.push(
        <div key={`key99458${row._id}`}>
          <button className="btn btn-sm btn-info session-code-button-radial" id={`popover99490${row._id}-${index}`}> 99490 </button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99490${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              There has been an attestation of a phone consultation and at least
              20 minutes of Chronic Care Management performed on this patient this
              month.
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99490 ----------------------------------//

  //*******************************************
  // CCM: CPT CODE - 99439 Information
  //*******************************************
  //* 99439 - This CCM CPT code is populated IF the patient qualified for 99490 AND had an additional 20 minutes of review, 
  //* in excess of the required 20 minutes for CPT 99490. For example if the patient’s total review time was more than 40 minutes, 
  //* we would populate one 99439 for the additional 20 minutes over the required 20 minutes. 
  //* If the total review time was over 60 minutes we would populate (2) 99439 CPT Codes because there were two separate additional 20 minutes 
  //* reviewed in excess to the initial 20 minutes required for CPT 99454. This CPT code can only be billed 2 times each month, 
  //* so if there is more than 60 minutes of review we only populate (2) 99439, no matter how much time. They limit 99439 to only (2) per month.
  //---------------------------------- START CPT CODE 99439 ----------------------------------//
  if (codeModel && _.size(codeModel.cpt99439) > 0) {
    cptCode99439 = [];
    _.map(codeModel.cpt99439, (value, index) => {
      cptCode99439.push(
        <div>
          <button className="btn btn-sm btn-warning session-code-button-radial" id={`popover99439${row._id}-${index}`}>99439</button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99439${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              There has been an attestation of an additional 20 minutes of
              Chronic Care Management performed on this patient this month.
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- END CPT CODE 99439 ----------------------------------//

  //---------------------------------- CPT CODE 99453 ---------------------------------------//
  // Initial setup && Completed Call
  // This RPM CPT code is only populated once. The first time we bill for 99454, 
  // we bill for 99453. This code is for the initial training and
  //-----------------------------------------------------------------------------------------//
  if (codeModel && _.size(codeModel.cpt99453) > 0) {
    cptCode99453 = [];
    _.map(codeModel.cpt99453, (value, index) => {
      cptCode99453.push(
        <div>
          <button className="btn btn-sm btn-primary text-white session-code-button-radial" id={`popover99453${row._id}-${index}`}> 99453 </button>
          <UncontrolledPopover trigger="legacy" placement="top" target={`popover99453${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              Patient have met the condition Initial Setup & Training Completed!
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  //---------------------------------- CPT CODE 99454 ---------------------------------------//
  // This RPM CPT code is populated every month, 
  // IF the patient has had at least 16 unique days with any type of a vital upload, within that month.
  //-----------------------------------------------------------------------------------------//
  if (codeModel && _.size(codeModel.cpt99454) > 0) {
    cptCode99454 = [];
    _.map(codeModel.cpt99454, (value, index) => {
      cptCode99454.push(
        <div>
          <button className="btn btn-sm btn-success text-white session-code-button-radial" id={`popover99454${row._id}-${index}`} type="button">99454</button>
          <UncontrolledPopover trigger="legacy" placement="right" target={`popover99454${row._id}-${index}`}>
            <PopoverHeader>Description</PopoverHeader>
            <PopoverBody>
              Patient have met the condition at least 16 days on the system and
              should have records of activity every month!
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    });
  }
  if (cptCode99490 || cptCode99439 || cptCode99453 || cptCode99454 || cptcode99457 || cptcode99458) {
    return (
      <div className="mx-auto text-center">
        {cptCode99453} {cptCode99454}
        {cptcode99457} {cptcode99458}
        {cptCode99490} {cptCode99439}
      </div>
    );
  } else {
    return (
      <div className="mx-auto text-center">
        -
      </div>
    );
  }
}

/*
 * Print UI of table UI
 */
class CCMListClassPrint extends React.Component {
  constructor(props) {
    super(props);
  }
  render() { 
    document.title = "RPM / CCM Monthly Attestation and Clinical Report";  
    const printPageStyle = "@page { size: A4 landscape; }";
    const props = this.props;
    const NoDataIndication = () => (
      <div>
        {props.loading && (
          <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
        )}
        {!props.loading && (
          <LottieLoader
            loadLoop={false}
            autoplayLoop={true}
            source={lottieEmptyFile}
            Lheight={300}
            Lwidth={300}
            title={"Sorry! No pm session record found :("}
            subTitle={"We can't find any pm session record."}
          />
        )}              
      </div>
    );

    return (
      <div id="printCCM" className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print" >
        <img
            src={`${process.env.REACT_APP_VIDEO_CALL_REQUEST}/static/media/smarthealth.c3ca62dd.jpg`} 
            alt="new"
            width="290px"
          />
          <Row className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print" style={{ margin: 10,padding:0 }}>
            <Col className="notes-pdf-title">              
              <img
                src="https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/computer-512.png"
                width="50px"
                className="img-circle"                
              />
              <br />
              <span className="text-center-title" style={{ fontWeight: 600, textAlign: "end",fontSize:18 }}>
                {"\u00a0\u00a0"} PM Session
              </span>
              <br />
            </Col>
            <Col className="notes-pdf-details">
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "start",fontSize:16 }}><i class="mdi mdi-calendar" style={{color:"#ff5722"}}></i> Start Date :</td>
                    <td style={{ fontWeight: 600, textAlign: "start",fontSize:16 }}>{"\u00a0\u00a0"}
                    {moment(props.startDate).format("LL")}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "start",fontSize:16 }}><i class="mdi mdi-calendar" style={{color:"#ff5722"}}></i> End Date :</td>
                    <td style={{ fontWeight: 600, textAlign: "start",fontSize:16 }}>{"\u00a0\u00a0"}{moment(props.endDate).format("LL")}</td>
                  </tr>                  
                </tbody>
              </table>
            </Col>
          </Row>
        <BootstrapTable
          wrapperClasses="table-responsive"
          keyField="_id"
          noDataIndication={ () => <NoDataIndication /> }
          data={_.orderBy(props.pmRecords, "lname", "asc")}
          columns={[
            {
              dataField: "lname",
              text: "Last, First Name",
              formatter: fullname,
              classes:"table-print",
              sort: false,
              headerStyle: (colum, colIndex) => {
                return { width: '100px', textAlign: 'left' };
              }
            },
            {
              dataField: "bday",
              text: "DOB",
              classes:"table-print",
              formatter: (cell, row) => {
                return moment(row.bday).format("MMMM DD, YYYY");
              },
              sort: false,
              style: {
                width: '10px'
              }
            },
            {
              dataField: "deviceReadingDate",
              text: "Last Upload Date",
              formatter: (cell, row) => {
                if (row.lastReading && row.lastReading.deviceReadingDate) {
                  return moment(row.lastReading.deviceReadingDate).format(
                    "MMMM DD, YYYY"
                  );
                } else {
                  return row.deviceReadingDate ? moment(row.deviceReadingDate).format("MMMM DD, YYYY") : "NONE";
                }
              },
              sort: false,
              classes:"table-print",
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px'
              }
            },
            {
              dataField: "conditions",
              text: "Condition(s) Managed",
              formatter: (cell, row) => {
                if (row.conditions && row.conditions.length > 0) {
                  var x = row.conditions.toString();

                  var a = [];
                  var i = 15;

                  do {
                    a.push(x.substring(0, i));
                  } while ((x = x.substring(i, x.length)) != "");

                  a = a.join("\n");
                  return a;
                }
                return "";
              },
              classes:"table-print",
              sort: false,              
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px', wordBreak: "break-word"
              }
            },
            {
              dataField: "mainCareProviders",
              text: "Main Care Provider",
              classes:"table-print",
              sort: false,
              formatter: (cell, row) => {
                if (row.mainCareProviders && row.mainCareProviders.length > 0) {
                  var x = row.mainCareProviders.toString();

                  var a = [];
                  var i = 15;

                  do {
                    a.push(x.substring(0, i));
                  } while ((x = x.substring(i, x.length)) != "");

                  a = a.join("\n");
                  return a;
                }
                return "";
              },
              headerClasses: "ccmHeaderStyle",
            },
            {
              dataField: "organization",
              text: "Organization",
              classes:"table-print",
              sort: false,
              formatter: (cell, row) => {
                return row.organization ? row.organization : "-";
              },
              headerClasses: "ccmHeaderStyle",
            },
        
            {
              dataField: "enrolledPrograms",
              text: "Enrolled Program(s)",
              classes:"table-print",
              sort: false,
              formatter: (cell, row) => {      
                return (
                  <div className="text-center">
                    <span style={{textAlign:"center"}}>{row.enrolledPrograms ? row.enrolledPrograms : "-"}</span>
                  </div>
                );
              },
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px',
              }
            },
            {
              dataField: "totalCCMTime",
              text: "Total CCM Time", //"Grand Total for CCM Sessions",
              sort: false,
              classes:"table-print",
              formatter: (cell, row) => {                
                return (
                  <div className="text-center">
                    <h2 className="mb-0 ccmTimeText">{row.totalTimeCCM}</h2>
                    <small className="ccm-small-text">
                      <b>HOUR:MINUTE:SECOND</b>
                    </small>
                  </div>
                );
              },
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px',
                fontSize: '10px'
              }
            },
            {
              dataField: "totalRPMTime",
              text: "Total RPM Time", //"Grand Total for RPM Sessions",
              sort: false,
              classes:"table-print",
              formatter: (cell, row) => {                
                return (
                  <div className="text-center">
                    <h2 className="mb-0 ccmTimeText">{row.totalTimeRPM}</h2>
                    <small className="ccm-small-text">
                      <b>HOUR:MINUTE:SECOND</b>
                    </small>
                  </div>
                );
              },
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px',
                fontSize: '10px'
              }
            },
            {
              dataField: "asdassd",
              text: "Billable CPT Code(s)", // RPM
              sort: false,
              classes:"table-print",
              formatter: (cell, row) => {
                return billable(row);
              },
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px'
              }
            },
            {
              dataField: "insuranceProvider",
              text: "Primary Insurance Provider",
              sort: false,
              classes:"table-print",
              formatter: (cell, row) => {
                if (row.insuranceProvider) {
                  return <div style={{textAlign:"center"}}>{row.insuranceProvider}</div>;
                }
                return <div style={{textAlign:"center"}}> - </div>;
              },
              headerClasses: "ccmHeaderStyle"
            },
            {
              dataField: "isCalled",
              text: "Called",
              sort: false,
              classes:"table-print",
              formatter: (cell, row) => {                
                return (
                  <div className="text-center">
                    {row.isCalled && (
                      <img src={callingIcon} />
                    )}
                    {!row.isCalled && (
                      <div style={{textAlign:"center"}}>-</div>
                    )}
                  </div>
                );
              },
              headerClasses: "ccmHeaderStyle"
            }
          ]}
        />
      </div>
    );
  }
}
export default CCMListClassPrint;